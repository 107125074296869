.circularProgressContainer {
    width: 100%;
    padding: 10px;
}
.pieContainer {
  height: 300px;
  width: 300px;
  position: relative;
}

.pieBackground {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 100%;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.5);
} 

.pie {
  transition: all 1s;
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 100%;
  clip: rect(0px, 150px, 300px, 0px);
  background-color: #7d7d7d26;
  color:#282828;
  transition: all .2s ease-in-out;
}

#pieSlice1:hover {
  transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.hold {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 100%;
  clip: rect(0px, 300px, 300px, 150px);
}

#pieSlice1 .pie {
  /* background-color: #1b458b; */
  transform:rotate(68deg) ;
}

#pieSlice2 {
  transform: rotate(70deg);
}

#pieSlice2 .pie {
  /* background-color: #0a0; */
  transform: rotate(58deg);
}

#pieSlice3 {
  transform: rotate(130deg);
}

#pieSlice3 .pie {
  /* background-color: #f80; */
  transform: rotate(78deg);
}

#pieSlice4 {
  transform: rotate(210deg);
}


#pieSlice4 .pie {
  /* background-color: #08f; */
  transform: rotate(148deg);
}

/* #pieSlice5 {
  transform: rotate(240deg);
}

#pieSlice5 .pie {
  background-color: #a04;
  transform: rotate(118deg);
} */

/* #pieSlice6 {
  transform: rotate(290deg);
}

#pieSlice6 .pie {
  background-color: #ffd700;
  transform: rotate(70deg);
} */
.innerCircle {
  position: absolute;
  width: 190px;
  height: 190px;
  background-color: white;
  /* background: radial-gradient( circle closest-corner at center 100px, #222, black 80% ) no-repeat; */
  /* background: radial-gradient( circle closest-corner at center 100px, #222, var(--sub) 80% ) no-repeat; */
  border: 5px solid black;
  border-radius: 100%;
  top: 55px;
  left: 55px; 
  box-shadow: 0px 0px 8px rgba(0,0,0,0.5) inset;
  color: white;
}
.innerCircle::before {
  position: absolute;
  width: 190px;
  height: 190px;
  /* background-color: #444; */
  background: repeating-linear-gradient(
    45deg,
    #313131,
    #313131 2px,
    #020202 2px,
    #020202 5px
  );
  border: 5px solid black;
  border-radius: 100%;
  top: 55px;
  left: 55px; 
  box-shadow: 0px 0px 8px rgba(0,0,0,0.5) inset;
  color: white;
}

.innerCircle .content {
  position: absolute;
  display: block;
  width: 200px;
  height: 200px;
  top: 50px;
  left: 0;
  text-align: center;
  font-size: 14px;
  /* background: repeating-linear-gradient(
    45deg,
    #313131,
    #313131 2px,
    #020202 2px,
    #020202 5px
  ) */
}
.eyes{
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow:
    inset 0 0 5px #fff,
    inset 2px 0 8px #86afc5,
    inset -2px 0 8px #0ff,
    inset 2px 0 30px #86afc5,
    inset -2px 0 30px #0ff,
    0 0 5px #fff,
    -1px 0 8px #86afc5,
    1px 0 8px #0ff;
}
.leyes{
    left: 40px;
}
.reyes{
    right: 60px;
}

div.circTxt {
  /*allows for centering*/
  display: inline-block;
  /*adjust as needed*/
  margin-bottom: 128px;
  color: whitesmoke;
}

.circleText{
  top:15px;left:0}

  .activeSlice {
    background-color: var(--sub);
   
  }
  .activeColor > span{
    color: var(--tracking-secondary-color);
  }
  .inactiveColor> span{
    color:#545454;
    font-weight: 700;
  }