:root {
  --tracking-primary-color: #d90d52 ;
  --sub: #d90d52 ;
  --main: #ffffff ;
  --tracking-secondary-color: #ffffff ;
}

.App {
  text-align: center;
  width: 60%;
  margin: auto;
  overflow-x: hidden;
}
@media screen and (max-width:992px){
  .App {
    text-align: center;
    width: 90%;
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* search page or home page */

body{
  background: white;
  font-family: 'Open Sans', sans-serif;
}

.centerContent{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  flex-direction: column;
}
.companytitle{
  color:var(--sub);
}

.search-icon{
  position: absolute;
  left: 40%;
  top: 25%;
  font-size: 20px;
  -webkit-transform: rotate(-45deg); 
               -moz-transform: rotate(-45deg); 
                 -o-transform: rotate(-45deg);
                    transform: rotate(-45deg);
}


.search-box {
  width: 100%;
  position: relative;
  display: flex;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
}

.search-input {
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  padding: 15px 45px 15px 15px;
  background-color: #eaeaeb;
  color: #6c6c6c;
  border-radius: 6px;
  border:none;
  transition: all .4s;
}

.search-input:focus {
  border:none;
  outline:none;
  box-shadow: 0 1px 12px #b8c6db;
  -moz-box-shadow: 0 1px 12px #b8c6db;
  -webkit-box-shadow: 0 1px 12px #b8c6db;
}

.search-btn {
  background-color: transparent;
  font-size: 20px;
  width: 12%;
  margin-left: -10%;
  border: none;
  color: #615f5f;
  transition: all .4s;
  position: relative;
  z-index: 10;
}

.track-btn {
  background-color: var(--sub);
  font-size: 16px;
  width: 210px;
  border: none;
  color: var(--tracking-secondary-color);
  transition: all .4s;
  border-radius: 5px;
  padding: 10px;
  justify-content: center;
  margin-bottom: 15px;
  display:inline-flex;
  cursor: pointer;
  line-height: 20px;
  align-items: center;
}

.search-btn:hover {
  transform: scale(1.2);
  cursor: pointer;
  color: black;
}

.search-btn:focus {
  outline:none;
  color:black;
}

.error-message {
  color: #cc0033;
  display: inline-block;
  font-size: 14px;
  line-height: 15px;
  margin: 5px 0 0;
}

.input-group .error-message {
  display: none;
}

.error .error-message {
  display: inline-block;
}
.input-group {
  line-height: 20px;
  height: 70px;
  width: 100%;
  margin-top: 10px;
}

/*Resize the wrap to see the search bar change!*/
.wrap{
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* result page */

.dflex{
  display: flex;
  flex-direction: column;
}
.dflex-row{
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
}
.dflexSeperator{
  padding-top: 4px;
  padding-bottom: 20px;
  text-align: left;
  width: 100%;
}
.dflex-row-equal {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width:768px){
  .dflexSeperator{
    padding-left: 20px;
  }
  .dflex-row-equal {
    flex-direction: column;
  }
  .absWrap {
    margin: auto;
  }
  .oaerror{
    width:80%;
  }
}
.title{
  padding-top: 20px;
  padding-bottom: 40px;
}

.backbtnContainer{
  padding-top: 20px;
  padding-bottom: 20px;
  display:flex;
  justify-content: flex-start;
}
.backbtnContainerMargin {
  margin-left: 20%;
}
.custom-btn {
  width: 150px;
  height: 40px;
  color: var(--tracking-secondary-color);
  border-radius: 10px;
  padding: 10px 25px;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  background: var(--sub);
  border: 1px solid lightgray;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
   box-shadow:inset 2px 2px 2px 0px rgba(255,255,255,.5),
   7px 7px 20px 0px rgba(0,0,0,.1),
   4px 4px 5px 0px rgba(0,0,0,.1);
  outline: none;
  border: none;
}


.btn-16 {
  border: none;
  color: #000;
}
.btn-16:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  top: 0;
  left: 0;
  direction: rtl;
  z-index: -1;
  box-shadow:
   -7px -7px 20px 0px #fff9,
   -4px -4px 5px 0px #fff9,
   7px 7px 20px 0px #0002,
   4px 4px 5px 0px #0001;
  transition: all 0.3s ease;
}
.btn-16:hover {
  color: #000;
}
.btn-16:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}
.btn-16:active {
  top: 2px;
}

.error-notice{
  margin:5px; /* Make sure to keep some distance from all sides */
}

.oaerror{
  width:60%;
  background-color: #ffffff;
  padding:20px;
  border:1px solid #eee;
  border-left-width:5px;
  border-radius: 3px;
  margin:10px auto;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

.danger{
  border-left-color: #d9534f; /* Left side border color */
  background-color: rgba(217, 83, 79, 0.1); /*Same color as the left border with reduced alpha to 0.1*/
}

.danger strong{
  color:#d9534f;
}

@media screen and (max-width:768px){

  .oaerror{
    width:80%;
  }
  .backbtnContainerMargin {
    margin-left: 10%;
  }
}

.contentBackground{
  background: #f7f7fa;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    border: 1px solid #eeeef0;
    border-radius: 10px;
    padding: 20px;
}

.secondary{
  color: gray;
}
.primary{
  color:var(--sub);
}

.wd33{
  width: 33%;
}
.wd50{
  width: 50%;
}
.wd100{
  width:100%;
}
.jc-start{
  justify-content: flex-start;
}

.ai-start{
  align-items: flex-start;
}
.pd-row{
  margin-top: 5px;
  margin-bottom: 5px;
}
.font12{
  font-size: 12px;
}
.font14{
  font-size: 14px;
}
.font-bold{
  font-weight: 700;
}
.hrline{
  border-bottom: 1px solid lightgray;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 5px;
}
.anchorButton{
  outline: none;
  text-decoration: underline;
  background: none!important;
  border: none;
  padding: 0!important;
  cursor: pointer;
}

.hyperText{
  color: grey;
  font-size: 12px;
}

.pb5{
padding-bottom: 5px;
}

.absWrap{
  position: relative;
}
.absContainer{
  position: absolute;
  right: 0;
  top: -30px;
}

.linearProgressWrap{
  padding-top: 180px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 25px;
  height: 25px;
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1s linear infinite;
  margin-left: 10px;
}

.mt-2 {
  margin-top: 0.5rem;
}

.companytitle img {
  width:  200px;
  height: 140px;
  object-fit: contain;
}