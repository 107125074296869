.modal {
    /* position: fixed; */
    top: 0;
    right: 0;
    width: 45%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    box-shadow: 2px 0 10px 5px rgb(0 0 0 / 50%);
    max-height: 100%;
    position: fixed;
    z-index: 100;
  }
  
  .modal .close-button {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 0 5px;
    border: 0;
    background: 0 0;
    color: #000;
    z-index: 1;
    
}

.icon-close{
    width: 12px;
    height: 12px;
    font-size: 20px;
}
.modal h1 {
    padding: 0 0 10px;
    border-bottom: 2px dotted #888;
    color: #000;
    font-size: 1.5rem;
    line-height: 1.08;
    text-align: left;
}
.modal h2, .reactapps .modal h3 {
    margin: 30px 0 0;
    font-size: 1.25rem;
}

.modal p {
    margin: 0.875rem 0;
    font-weight: 500;
    line-height: 1.33;
    color: #6C6C6C;
}

.modal ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}
.modal li {
    margin: 0 0 30px;
    line-height: 1.33;
    color: #6C6C6C;
}

  .modal-main {
    position:absolute;
    background: white;
    height: auto;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    /* transform: translate(-50%,-50%); */
    padding: 60px 30px;
    text-align: left;
  }
  
  .display-block {
    display: block;
  }
  
  .display-none {
    display: none;
  }