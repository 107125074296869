    *,::after,::before{box-sizing:inherit;}
    table{border-collapse:collapse;   border-spacing: 0;}
    th{text-align:left;}
    *{margin:0;padding:0;box-sizing:border-box;}
    table{border-spacing:1;border-collapse:collapse;background:#fff;border-radius:10px;overflow:hidden;width:100%;margin:0 auto;position:relative;}
    table *{position:relative;}
    table td,table th{padding-left:8px;}
    table thead tr{height:60px;background:#f5f5f5;color: black;border-bottom: 1px solid lightgray;}
    table tbody tr{height:50px;}
    table td,table th{text-align:left;}
    table td{padding-top:10px;padding-bottom:8px;}
    .wrap-table100{width:100%;}
    .table100-head th{font-size:18px;color:black;line-height:1.2;font-weight:unset;}
    tbody tr{font-size:15px;color:gray;line-height:1.2;font-weight:unset; 
        border-bottom: 1px solid lightgray;}
    tbody tr:hover{color:#555;background-color:#f5f5f5;cursor:pointer;}
    .column1{width:30%;padding-left:10px;}
    .column2{width:30%;}
    .column3{width:40%;padding-right: 10px;text-align:right}
    .column3 p{padding-bottom:1px;}
  
    @media screen and (max-width:992px){
    table{display:block;}
    table>*,table tr,table td,table th{display:block;}
    table thead{display:none;}
    table tbody tr{height:auto;padding:10px 0;}
    table tbody tr td{padding-left:40%!important;margin-bottom:4px;border-bottom: 1px solid #eee; }
    table tbody tr td:before{font-size:14px;color:#999;line-height:1.2;font-weight:unset;position:absolute;width:40%;left:30px;top:10px;}
    table tbody tr td:nth-child(1):before{content:"Date";}
    table tbody tr td:nth-child(2):before{content:"Location";}
    table tbody tr td:nth-child(3):before{content:"Event";}
    .column1,.column2,.column3{width:100%;}
    .column3{text-align:left;}
    tbody tr{font-size:14px;}
    }
    @media (max-width:576px){
    .container-table100{padding-left:5px;padding-right:5px;}
    }
